@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: "Inter", sans-serif !important;
  text-decoration: none;
  list-style: none;
  /* list-style: inherit; */
}

body {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

code {
  font-family: "Inter", sans-serif;
}

.tox-notifications-container,
.tox-notification {
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  display: none !important;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  transform: translateY(-50%);

}


